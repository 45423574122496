//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

$font-family-base: Helvetica Neue,Helvetica,YuGothic,Yu Gothic,'ヒラギノ角ゴ Pro W3',Hiragino Kaku Gothic Pro,"メイリオ",Meiryo,"ＭＳＰゴシック",sans-serif;

@import "variables";
@import "bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
}
.mincho{
    font-family: "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
}
.box-shadow img
{
    box-shadow: 10px 10px var(--bs-primary);
}

.box-shadow-left
{
    position: relative;
    margin-top: 100px;

    &:before{
        top: -10%;
        position: absolute;
        content: '';
        background-image: url('/wp-content/themes/isewakon.com/dist/images/common/container_bg.png');
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 60%;
        }
        height: 110%;
        z-index: -1;
    }
}
.box-shadow-right
{
    position: relative;
    margin-top: 80px;

    &:before{
        top: -10%;
        right: 0;
        position: absolute;
        content: '';
        background-image: url('/wp-content/themes/isewakon.com/dist/images/common/container_bg.png');
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 60%;
        }
        height: 110%;
        z-index: -1;
    }
}
/*
.container-bg{
    background-image: url('/wp-content/themes/isewakon.com/dist/images/common/container_bg.png');
    background-size: 100%;
    background-repeat: repeat-y;
}
*/
//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/wordpress";
@import "components/icomoon";
@import "components/hamburgers";
